.portfolio-card {
    border: 1px solid rgba(8, 235, 235, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    h5 {
        color: rgb(13, 202, 240);
    }
  }

.portfolio-card:hover,
.portfolio-card:hover .card-body {
    border-color: rgba(8, 235, 235, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
}

.portfolio-card:hover h5 {
    color: rgb(44, 241, 241);
}

.portfolio-card:hover .custom-icon {
    transform: translateY(-3px) translateX(3px);
}

@media (max-width: 767px) {
    .portfolio-card,
    .portfolio-card .card-body,
    .portfolio-card .custom-icon {
      border-color: rgba(8, 235, 235, 0.5);
      background-color: rgba(0, 0, 0, 0.3);
    }
    h5 {
        color: rgb(13, 202, 240);
    }
}